.switch {
	position: relative;
	display: inline-block;
	width: 4em;
	height: 2em;
	vertical-align: middle;
}

.switch .slider {
	border: none;
	background-color: #e2e6ea;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	transition-duration: 0.4s;
}

.slider:before {
	border-radius: 0.25rem;
	position: absolute;
	content: "";
	height: 1.5em;
	width: 1.5em;
	margin: 0.25em;
	background-color: #FFF;
	left: 0;
	top: 0;
	transition-duration: 0.4s;
}

.switch input:checked + .slider {
	background-color: #007bff;
}

.switch input:checked + .slider:before {
	margin-left: 2.25em;
}
