.order {
	padding: 5px 5px 1.2em;
	border-left: 1px solid #777;
	border-right: 1px solid #777;
}

.order.highlight .link-tile {
	background-color: #f9f698;
}

.order:not(.b2c).no-stickers .link-tile {
	background-color: #98ebf9;
}

.order.first {
	border-top: 1px solid #777;
}

.order:last-child {
	border-bottom: 1px solid #777;
}

.order .link-tile {
	color: inherit;
	text-decoration: none;
	display: block;
}

.order .link-tile:hover {
	background-color: #f9f9f9;
}

.order h4 {
	font-size: 1.5em;
}

.order button {
	line-height: 1;
	margin-left: 0.25em;
}

.order button.btn-danger {
	margin-left: 1em;
}

.order .country {
	color: white;
	border-radius: 2px;
	padding: 0 3px;
	text-align: center;
	display: inline-block;
	width: 1.6em;
}

.order .status {
	font-variant: small-caps;
}

.order-progress {
	position: relative;
	height: 1em;
	white-space: nowrap;
}

.order-progress .percentage {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	text-align: center;
	font-size: 0.7em;
	pointer-events: none;
}

.order-progress > div {
	display: inline-block;
	height: 100%;
}

.order-progress .success {
	background-color: #00FF00;
}

.order-progress .failed {
	background-color: #FF0000;
}

.order-progress .none {
	background-color: #eee;
}

.order-progress .unknown {
	background-color: #FF00EF;
}

.color-unknown {
	color: #FF00EF;
}

.b2c h4 {
	color: purple;
}
