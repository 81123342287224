#orders {
	margin-top: 1em;
}

#orders ol {
	padding-top: 1em;
	list-style: none;
	padding-left: 0;
}

#orders > ol:empty {
	display: none;
}

.picker-name {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.locations > div {
	color: white;
	font-weight: bold;
	text-align: center;
}
