#stickers {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.sticker {
	border: 1px solid black;
	margin: 0.25em 0;
	width: 49%;
}

.box {
	padding: 0.25em 0.5em;
}

.box.top {
	border-bottom: 1px solid black;
}

.box.left {
	border-right: 1px solid black;
}

.box + .box {
	border-top: 1px solid black;
}

.address {
	padding-left: 3em;
	margin: 0;
}

.sticker > .row, .sticker > .row > *, .box > .row, .box > .row > * {
	margin: 0;
	padding: 0;
}

.dispatch {
	margin-top: 2em;
	text-transform: lowercase;
	font-variant: small-caps;
}

.dispatch::first-letter {
	text-transform: capitalize;
}

@page {
	size: A4 landscape;
	margin: 0;
}

@media print {
	html, body {
		width: 210mm;
		height: 297mm;
	}

	.sticker-page > form, .login {
		display: none;
	}

	.container-fluid {
		margin: 0;
		padding: 0;
	}

	#stickers {
		display: block;
		margin: 0.5cm;
	}

	.sticker {
		display: inline-block;
		margin: 0;
		width: 50%;
		height: 10cm;
		border: none;
	}

	svg {
		height: 2cm;
	}
}
