.dropship-order {
	background-color: #00BAFF;
}

.dropship-order > div {
	background-color: #fff;
}

.order-line {
	border-left: 5px solid;
	padding: 0.25em;
	user-select: none;
}

.order-line.first {
	border-top: 5px solid;
}

.order-line:not(.first) {
	padding-top: 2em;
}

.order-line .row {
	cursor: pointer;
}

.order-line img {
	max-width: 100%;
}

.order-line .line-status label.btn {
	margin-bottom: 0;
}

.order-line .line-status label.btn:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.order-line .line-status label.btn:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.order-status {
	text-align: center;
}

.order-status > p {
	display: inline-block;
	margin: 0 0.5em;
}

.order-line h2.text-center {
	white-space: nowrap;
}

.relevant-number {
	display: inline-block;
}

.relevant-number::first-letter {
	color: red;
}
