.packing-slip img {
	max-height: 2em;
}

@media print {
	.packing-slip .login, .packing-slip .btn {
		display: none;
	}

	.packing-slip {
		padding-top: 4cm;
		padding-right: 4.8cm;
	}

	.page-break-before {
		padding-top: 4cm;
		page-break-before: always;
	}
}
