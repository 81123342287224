body {
	overscroll-behavior: none;
}

header .row {
	margin-top: 1em;
	margin-bottom: 1em;
}

iframe {
	border: none;
	height: 3em;
	width: 150px;
}

.form-inline input {
	margin-right: 0.5em;
}

#connection-status {
	position: fixed;
	top: 0;
	right: 0;
	padding: 0.25em 1em;
	pointer-events: none;
}

.order-day-tabs {
	margin-top: 1em;
}

.nav-item a {
	cursor: pointer;
}

.order-day-tabs .nav-item .btn {
	color: white;
	font-size: 1.5em;
	line-height: 1;
}

.login {
	margin-bottom: 0.25em;
}

.login .role {
	font-variant: small-caps;
}

input[type="number"]:placeholder-shown {
	font-style: italic;
}
